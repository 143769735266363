.BookPreviewContainer_b7dq9pz{box-shadow:0 1px 4px rgb(0 0 0 / 30%);background-color:#fff;font-family:Roboto,Tahoma,Helvetica,sans-serif;font-weight:400;font-size:0.875rem;line-height:1.3;text-align:center;border-radius:2px;color:#3e5c6d;max-width:335px;width:100%;}
.BookPreviewContentWrapper_b1vexyf4{padding:10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;row-gap:10px;}
.BookPreviewContent_byxl45u{position:relative;height:100px;min-width:75px;}
.BookPreviewInfo_bh5l6pg{width:100%;}
.BookContent_b3nyueo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;-webkit-column-gap:25px;column-gap:25px;}@media (min-width:740px){.BookContent_b3nyueo{-webkit-column-gap:10px;column-gap:10px;}}@media (min-width:850px){.BookContent_b3nyueo{-webkit-column-gap:25px;column-gap:25px;}}
.BookPreviewTitleContainer_b1t821m8{margin-bottom:5px;}@media (min-width:850px){.BookPreviewTitleContainer_b1t821m8{font-size:1.125rem;}}
.BookPreviewTitle_bidsg9j{margin:0;text-align:left;font-size:inherit;}@media (min-width:740px){.BookPreviewTitle_bidsg9j{font-size:1rem;}}@media (min-width:1100px){.BookPreviewTitle_bidsg9j{font-size:1.125rem;}}
.BookPreviewPriceSection_b11nb4fs{display:block;font-size:40px;font-family:Oswald,Arial Narrow,Impact,sans-serif;text-align:start;}.BookPreviewPriceSection_b11nb4fs:before{content:var(--b11nb4fs-1);font-size:0.875rem;vertical-align:top;}@media (min-width:740px){.BookPreviewPriceSection_b11nb4fs:before{font-size:1rem;}}@media (min-width:1100px){.BookPreviewPriceSection_b11nb4fs:before{font-size:1.125rem;}}
.BookPreviewActionsContainer_bd45ll9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-column-gap:10px;column-gap:10px;}
.BookPreviewSellLink_b19h9cmf{color:#fff;background-color:#000;border-radius:2px;border:0;font-size:1.125rem;font-weight:700;font-family:Roboto,Tahoma,Helvetica,sans-serif;line-height:1;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;-webkit-text-decoration:none;text-decoration:none;box-sizing:border-box;-webkit-transition:all 0.3s;transition:all 0.3s;-webkit-transition-property:color,background-color;transition-property:color,background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#f15726;width:50%;height:40px;padding:0;line-height:1;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;font-size:1rem;font-weight:500;}.BookPreviewSellLink_b19h9cmf svg{margin-right:10px;}.BookPreviewSellLink_b19h9cmf:hover,.BookPreviewSellLink_b19h9cmf:focus{background-color:#d63e0e;color:#e6e6e6;}.BookPreviewSellLink_b19h9cmf[disabled]{background-color:#d63e0e;color:#d9d9d9;cursor:default;}.BookPreviewSellLink_b19h9cmf.active{background-color:#fff;border:1px solid #f15726;color:#f15726;}@media (min-width:850px){.BookPreviewSellLink_b19h9cmf{font-size:1rem;}}
.BookPreviewBuyLink_bqs1hs1{color:#fff;background-color:#000;border-radius:2px;border:0;font-size:1.125rem;font-weight:700;font-family:Roboto,Tahoma,Helvetica,sans-serif;line-height:1;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;-webkit-text-decoration:none;text-decoration:none;box-sizing:border-box;-webkit-transition:all 0.3s;transition:all 0.3s;-webkit-transition-property:color,background-color;transition-property:color,background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#50b5c3;width:50%;height:40px;padding:0;line-height:1;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;font-size:1rem;font-weight:500;}.BookPreviewBuyLink_bqs1hs1 svg{margin-right:10px;}.BookPreviewBuyLink_bqs1hs1:hover,.BookPreviewBuyLink_bqs1hs1:focus{background-color:#6bafb9;color:#e6e6e6;}.BookPreviewBuyLink_bqs1hs1[disabled]{background-color:#d6d8dd;color:#fff;cursor:default;}@media (min-width:850px){.BookPreviewBuyLink_bqs1hs1{font-size:1rem;}}
