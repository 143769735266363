.MainPageContainer_m1ap6vm6.PageMainContainer_p2liko{color:#3e5c6d;}
.MainPageSection_m1fqv0j0{background-color:var(--m1fqv0j0-0);margin-bottom:var(--m1fqv0j0-1);}
.StepContentSection_szrfv2o.MainPageSection_m1fqv0j0{position:relative;}
.AdvantagesSection_a6igdph.MainPageSection_m1fqv0j0{padding-top:50px;}
.SearchSectionWrapper_s1u4umcp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;max-width:1200px;}
.SearchMainPageSection_s1rqv7qg.MainPageSection_m1fqv0j0{padding-bottom:40px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.SubTitle_s191x6bp{font-size:28px;font-weight:400;font-family:Oswald,Arial Narrow,Impact,sans-serif;color:#3e5c6d;line-height:1.3;margin-top:10px;margin-bottom:20px;}
.SubTitleCentered_sumc0i.SubTitle_s191x6bp{text-align:center;margin-top:0;margin-bottom:24px;font-size:24px;}@media (min-width:740px){.SubTitleCentered_sumc0i.SubTitle_s191x6bp{font-size:28px;}}
.NoDataTitle_npss15q{font-size:1.125rem;text-align:center;}
.SliderWrapper_s1396odj{max-width:730px;width:100%;padding:20px 20px 40px 20px;border-radius:6px;box-sizing:border-box;}@media (min-width:600px){.SliderWrapper_s1396odj{padding:20px 60px 60px 60px;}}@media (min-width:740px){.SliderWrapper_s1396odj{display:none;}}
.StepContent_s1calfot.ContentWrapper_c1b1xox{position:absolute;display:block;padding:0;margin-top:-32px;left:20px;width:calc(100% - 40px);background:#ffffff;border-bottom:4px solid #50b5c3;border-radius:0 0 4px 4px;box-shadow:0 1px 4px 0 #00000040;}@media (min-width:740px){.StepContent_s1calfot.ContentWrapper_c1b1xox{width:72%;left:15%;}}@media (min-width:1100px){.StepContent_s1calfot.ContentWrapper_c1b1xox{width:546px;left:calc(50% - 273px);margin-top:-32px;}}
.StepsItemsWrapper_s13nf5or{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:space-around;-webkit-justify-content:space-around;-ms-flex-pack:space-around;justify-content:space-around;row-gap:5px;padding-top:16px;padding-bottom:16px;margin:0 auto;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.StepWrapper_s16euwtp{text-align:center;margin:0 5px;width:33%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media (min-width:400px){.StepWrapper_s16euwtp{max-width:27%;}}@media (min-width:490px){.StepWrapper_s16euwtp{max-width:22%;}}@media (min-width:600px){.StepWrapper_s16euwtp{margin:0 5px;max-width:31%;}}@media (min-width:850px){.StepWrapper_s16euwtp{max-width:30%;}}
.NoBreak_n1oovzmy{white-space:nowrap;}@media (min-width:1200px){.NoBreak_n1oovzmy{white-space:normal;}}
.StepLineSeparator_s1fo6mia{width:2px;height:42px;background:#50b5c3;}
.StepTitle_s8c5nhw{font-size:1rem;font-family:Oswald,Arial Narrow,Impact,sans-serif;font-weight:400;text-transform:uppercase;margin-bottom:5px;color:#f15726;}@media (min-width:740px){.StepTitle_s8c5nhw{margin-bottom:10px;font-size:20px;}}

.StepText_so5hsrn{line-height:1;font-family:Roboto,Tahoma,Helvetica,sans-serif;font-weight:400;margin:0;padding:0;font-size:0.74rem;}@media (min-width:375px){.StepText_so5hsrn{font-size:0.875rem;}}
.OpportunitiesBooksContent_o1fi9r6y.ContentWrapper_c1b1xox{padding-top:52px;padding-bottom:24px;}@media (min-width:740px){.OpportunitiesBooksContent_o1fi9r6y.ContentWrapper_c1b1xox{padding-top:48px;padding-bottom:4px;}}@media (min-width:850px){.OpportunitiesBooksContent_o1fi9r6y.ContentWrapper_c1b1xox{padding:48px 40px 4px 40px;}}
.OpportunitiesItems_o1b2ozp2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:335px;margin:0 auto;}@media (min-width:740px){.OpportunitiesItems_o1b2ozp2{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-top:74px;max-width:unset;}}@media (min-width:1100px){.OpportunitiesItems_o1b2ozp2{-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;margin-top:48px;}}
.OpportunitiesItemWrapper_o1ylnk9q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:24px;width:100%;}@media (min-width:740px){.OpportunitiesItemWrapper_o1ylnk9q{width:50%;margin-bottom:46px;}}@media (min-width:1100px){.OpportunitiesItemWrapper_o1ylnk9q{margin-right:20px;}.OpportunitiesItemWrapper_o1ylnk9q:last-child{margin-right:0;}}
.OpportunitiesImageWrapper_o1ileh2r{position:relative;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;width:82px;height:82px;margin-right:10px;}
.OpportunitiesTextWrapper_o1qk8fje{max-width:206px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.OpportunitiesTextWrapper_o1qk8fje p{margin:0;}@media (min-width:740px){.OpportunitiesTextWrapper_o1qk8fje{max-width:220px;}}
.SuggestedBooksWrapper_saufzqh.ContentWrapper_c1b1xox{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:space-around;-webkit-justify-content:space-around;-ms-flex-pack:space-around;justify-content:space-around;padding-top:30px;padding-bottom:30px;}.SuggestedBooksWrapper_saufzqh.ContentWrapper_c1b1xox .SubTitle_s191x6bp{margin-top:0;font-size:1rem;text-align:center;}@media (min-width:740px){.SuggestedBooksWrapper_saufzqh.ContentWrapper_c1b1xox{display:block;}.SuggestedBooksWrapper_saufzqh.ContentWrapper_c1b1xox .SubTitle_s191x6bp{font-size:28px;}}
.HighResaleWrapper_hdxtvdn{position:relative;height:var(--hdxtvdn-0);}
.HighResaleLearnMoreWrapper_h1umno5q{margin-top:20px;}@media (min-width:740px){.HighResaleLearnMoreWrapper_h1umno5q{position:absolute;bottom:var(--h1umno5q-1);}}
.CardsContainer_c14wy225{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;row-gap:12px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (min-width:740px){.CardsContainer_c14wy225{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;row-gap:unset;-webkit-column-gap:24px;column-gap:24px;}}
.MobileAppContent_m1dafeu0.ContentWrapper_c1b1xox{padding-top:0;padding-bottom:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;position:relative;}
.MobileWrapper_m1620lin{background-image:url("../../../public/images/main/mobile-app-mobile.svg");background-position:center 20px;background-repeat:no-repeat;background-size:60%;padding-top:70%;padding-bottom:15px;}@media (min-width:600px){.MobileWrapper_m1620lin{background-image:url("../../../public/images/main/mobile-app-mobile.svg");padding-top:30px;padding-bottom:25px;background-size:65%;background-position:150% 15%;}}@media (min-width:850px){.MobileWrapper_m1620lin{background-position:90% 20%;background-size:40%;}}
.MobileTextContent_m1f5bsxn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.MobileTextContent_m1f5bsxn .SubTitle_s191x6bp{text-align:center;}@media (min-width:600px){.MobileTextContent_m1f5bsxn{width:50%;}.MobileTextContent_m1f5bsxn .SubTitle_s191x6bp{text-align:left;}}
.MobileDescription_m1yj0we0{margin:0;padding-bottom:12px;font-size:0.875rem;font-family:Roboto,Tahoma,Helvetica,sans-serif;font-weight:400;line-height:1.3;}@media (min-width:740px){.MobileDescription_m1yj0we0{font-size:20px;line-height:1.5;}}
.MobileLinkAccent_m1fuhhcl{color:#fff;background-color:#000;border-radius:2px;border:0;font-size:1.125rem;font-weight:700;font-family:Roboto,Tahoma,Helvetica,sans-serif;line-height:1;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;-webkit-text-decoration:none;text-decoration:none;box-sizing:border-box;-webkit-transition:all 0.3s;transition:all 0.3s;-webkit-transition-property:color,background-color;transition-property:color,background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:10px;padding-top:15px;padding-bottom:15px;padding-right:24px;padding-left:24px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;font-weight:400;-webkit-letter-spacing:0;-moz-letter-spacing:0;-ms-letter-spacing:0;letter-spacing:0;background-color:#f15726;}.MobileLinkAccent_m1fuhhcl svg{margin-right:10px;}@media (min-width:600px){.MobileLinkAccent_m1fuhhcl{max-width:290px;}}.MobileLinkAccent_m1fuhhcl:hover,.MobileLinkAccent_m1fuhhcl:focus{background-color:#d63e0e;color:#e6e6e6;}.MobileLinkAccent_m1fuhhcl[disabled]{background-color:#d63e0e;color:#d9d9d9;cursor:default;}.MobileLinkAccent_m1fuhhcl.active{background-color:#fff;border:1px solid #f15726;color:#f15726;}
.MobileLinkPrimary_mg5jvfs{color:#fff;background-color:#000;border-radius:2px;border:0;font-size:1.125rem;font-weight:700;font-family:Roboto,Tahoma,Helvetica,sans-serif;line-height:1;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;-webkit-text-decoration:none;text-decoration:none;box-sizing:border-box;-webkit-transition:all 0.3s;transition:all 0.3s;-webkit-transition-property:color,background-color;transition-property:color,background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:10px;padding-top:15px;padding-bottom:15px;padding-right:24px;padding-left:24px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;font-weight:400;-webkit-letter-spacing:0;-moz-letter-spacing:0;-ms-letter-spacing:0;letter-spacing:0;background-color:#50b5c3;margin-bottom:10px;}.MobileLinkPrimary_mg5jvfs svg{margin-right:10px;}@media (min-width:600px){.MobileLinkPrimary_mg5jvfs{max-width:290px;}}.MobileLinkPrimary_mg5jvfs:hover,.MobileLinkPrimary_mg5jvfs:focus{background-color:#6bafb9;color:#e6e6e6;}.MobileLinkPrimary_mg5jvfs[disabled]{background-color:#d6d8dd;color:#fff;cursor:default;}


.ScholarshipWrapper_s1vi1hn8{background-image:url("../../../public/images/main/student-image.svg");background-position:center 25px;background-repeat:no-repeat;background-size:52%;padding-top:70%;padding-bottom:25px;}@media (min-width:600px){.ScholarshipWrapper_s1vi1hn8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;background-size:30%;padding-top:20px;background-position:8% 100%;}}@media (min-width:740px){.ScholarshipWrapper_s1vi1hn8{background-size:36%;padding-top:40px;padding-bottom:40px;}}@media (min-width:850px){.ScholarshipWrapper_s1vi1hn8{background-size:30%;background-position:8% 100%;padding-top:70px;padding-bottom:70px;}}@media (min-width:1100px){.ScholarshipWrapper_s1vi1hn8{background-size:26%;}}


.RecentLink_r1f0fk2z.MobileLinkAccent_m1fuhhcl{height:40px;padding:0;max-width:225px;width:100%;margin:0 auto;text-transform:uppercase;font-weight:700;}
.RecentDescription_r1bpky46.MobileDescription_m1yj0we0{text-align:center;margin-top:20px;}
.BlogPostContent_b1tmn2m4.ContentWrapper_c1b1xox{padding-top:40px;padding-bottom:40px;}.BlogPostContent_b1tmn2m4.ContentWrapper_c1b1xox .SubTitle_s191x6bp{text-align:center;}
.BlogPostCards_b1dewens{display:none;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-items:center;padding-bottom:30px;grid-template-rows:1fr;grid-template-columns:1fr 1fr 1fr;row-gap:20px;-webkit-column-gap:30px;column-gap:30px;}@media (min-width:740px){.BlogPostCards_b1dewens{display:grid;}}@media (min-width:850px){.BlogPostCards_b1dewens{grid-template-columns:1fr 1fr 1fr;-webkit-column-gap:40px;column-gap:40px;}}
.BlogPostCategories_b6w77md{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.BlogPostCategoriesTitle_b1v7ignb{margin-top:0;margin-bottom:10px;font-size:20px;font-family:Oswald,Arial Narrow,Impact,sans-serif;text-align:center;line-height:1.3;width:100%;}
.CategoryLink_cgaowff{color:#fff;background-color:#000;border-radius:2px;border:0;font-size:1.125rem;font-weight:700;font-family:Roboto,Tahoma,Helvetica,sans-serif;line-height:1;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;-webkit-text-decoration:none;text-decoration:none;box-sizing:border-box;-webkit-transition:all 0.3s;transition:all 0.3s;-webkit-transition-property:color,background-color;transition-property:color,background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:0.750rem;background-color:#c4c4c4;color:#3e5c6d;font-weight:400;-webkit-letter-spacing:-0.45px;-moz-letter-spacing:-0.45px;-ms-letter-spacing:-0.45px;letter-spacing:-0.45px;display:inline-block;padding:8px 10px;margin:2px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:3px;white-space:nowrap;}.CategoryLink_cgaowff svg{margin-right:10px;}.CategoryLink_cgaowff:hover,.CategoryLink_cgaowff:focus{color:#fff;}.CategoryLink_cgaowff[disabled]{background-color:#d5dadc;color:#8c8c8c;cursor:default;}@media (min-width:400px){.CategoryLink_cgaowff{margin:5px;padding:8px 20px;}}
.ShimmeredCategory_sf36fps{border-radius:3px;}@media (min-width:400px){.ShimmeredCategory_sf36fps{margin:5px;}}.ShimmeredCategory_sf36fps span{height:28px;width:120px;}
.VideoContent_v1ef5u85.ContentWrapper_c1b1xox{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap-reverse;-ms-flex-wrap:wrap-reverse;flex-wrap:wrap-reverse;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-top:30px;padding-bottom:30px;}@media (min-width:600px){.VideoContent_v1ef5u85.ContentWrapper_c1b1xox{-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}}
.PlayerWrapper_p1t390tb{width:100%;border:20px solid #fff;}@media (min-width:600px){.PlayerWrapper_p1t390tb{width:50%;max-width:500px;}}
.VideoTextContent_v56nffq{padding-top:30px;}@media (min-width:400px){.VideoTextContent_v56nffq{padding-right:40px;}}@media (min-width:600px){.VideoTextContent_v56nffq{width:45%;max-width:400px;min-width:270px;padding-top:0;}}
.VideoTextPreview_v1ibqo2g{margin:0;margin-bottom:-10px;}
.VideoText_v19zfcr2{margin-bottom:12px;line-height:1.3;font-family:Roboto,Tahoma,Helvetica,sans-serif;font-size:0.875rem;font-weight:400;}@media (min-width:740px){.VideoText_v19zfcr2{font-size:1.125rem;}}
.RecommendationContent_r88ocs.ContentWrapper_c1b1xox{padding-top:10px;padding-bottom:30px;}@media (min-width:600px){.RecommendationContent_r88ocs.ContentWrapper_c1b1xox{padding-bottom:65px;}}
.RecommendationDescription_rbu9krm{margin-top:0;margin-bottom:12px;max-width:800px;color:#3e5c6d;}@media (min-width:600px){.RecommendationDescription_rbu9krm{margin-bottom:0;}}@media (min-width:740px){.RecommendationDescription_rbu9krm{font-size:1.125rem;}}
.RecommendationCards_rnj4wlk{-webkit-column-gap:10px;column-gap:10px;margin-top:40px;display:none;}@media (min-width:740px){.RecommendationCards_rnj4wlk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}@media (min-width:850px){.RecommendationCards_rnj4wlk{-webkit-column-gap:15px;column-gap:15px;}}@media (min-width:1100px){.RecommendationCards_rnj4wlk{-webkit-column-gap:30px;column-gap:30px;}}
.BubblesContainer_boyou1a{display:none;}@media (min-width:740px){.BubblesContainer_boyou1a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:24px 30px 0 0;width:50%;margin-left:-10%;}}@media (min-width:850px){.BubblesContainer_boyou1a{width:40%;margin-left:0;}}
.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{padding-bottom:20px;}@media (min-width:740px){.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:85%;padding:0 30px;}}@media (min-width:740px){.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{margin-top:0;}}@media (min-width:850px){.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{width:60%;}}@media (min-width:1100px){.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;padding-top:var(--h7nsuko-4);}}@media (min-width:1176px){.HeaderSearchContent_h7nsuko.ContentWrapper_c1b1xox{padding-top:62px;}}
.HeaderSearchTitle_h1qin8jw{font-size:28px;font-weight:400;margin-top:48px;text-align:center;}@media (min-width:600px){.HeaderSearchTitle_h1qin8jw{font-size:42px;margin-bottom:5px;}}@media (min-width:740px){.HeaderSearchTitle_h1qin8jw{max-width:380px;text-align:left;}}@media (min-width:1100px){.HeaderSearchTitle_h1qin8jw{max-width:100%;}}
.StrongSearchTitle_sah568u{color:#f15726;}
