.SearchTabsPanel_s192yceg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;margin-top:20px;margin-bottom:25px;}
.TippyContent_tqvtug1{color:#3e5c6d;padding:6px;line-height:19px;font-size:16px;}
.TippyStyled_tm3g1fj{width:280px;margin-top:1px;margin-right:15px;}@media (min-width:375px){.TippyStyled_tm3g1fj{margin-left:15px;left:0;width:auto;}}@media (min-width:740px){.TippyStyled_tm3g1fj{margin-left:25px;}}@media (min-width:1200px){.TippyStyled_tm3g1fj{margin-left:0;left:calc(50vw - 575px);}}@media (min-width:1330px){.TippyStyled_tm3g1fj{left:94px;}}

.Tab_t1gn1qq2{display:inline-block;padding:12px 20px;border:1px solid #3e5c6d;text-align:center;font-weight:400;font-size:0.875rem;color:#3e5c6d;cursor:pointer;-webkit-transition:background-color,color 0.3s;transition:background-color,color 0.3s;background-color:#ffffff;width:33.33%;}@media (min-width:740px){.Tab_t1gn1qq2{width:75px;}}@media (min-width:1100px){.Tab_t1gn1qq2{width:65px;}}.Tab_t1gn1qq2:first-child{border-right:none;border-radius:3px 0 0 3px;}.Tab_t1gn1qq2:last-child{border-left:none;border-radius:0 3px 3px 0;}.Tab_t1gn1qq2:hover{background-color:#ffe3da;}.Tab_t1gn1qq2.active{background-color:#f15726;border-color:#f15726;color:#ffffff;}.Tab_t1gn1qq2.active:hover{background-color:#d63e0e;border-color:#d63e0e;color:#e6e6e6;}
.Container_c80ffsr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:790px;box-sizing:border-box;}
.SearchContainer_s1hfha55{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;}@media (min-width:740px){.SearchContainer_s1hfha55{max-width:503px;}}
.SearchInput_s1s496fj{display:block;width:100%;padding:12px 34px 12px 18px;height:46px;border:1px solid #3e5c6d;border-right:none;border-radius:3px 0 0 3px;background:#fff;box-shadow:none;box-sizing:border-box;font-size:1rem;line-height:22px;outline:none;font-weight:400;}.SearchInput_s1s496fj:placeholder-shown{text-overflow:ellipsis;}@media (min-width:600px){.SearchInput_s1s496fj{font-size:1.125rem;padding:13px 18px;height:unset;}.SearchInput_s1s496fj:placeholder-shown{font-size:1rem;}}@media (min-width:740px){.SearchInput_s1s496fj{padding-left:45px;}}
.CameraSearch_cemr394{display:var(--cemr394-0);position:absolute;top:50%;right:var(--cemr394-1);width:33px;height:42px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);background-image:url("../../../public/icons/camera.svg");background-repeat:no-repeat;background-position:center;background-color:transparent;border:none;}@media (min-width:600px){.CameraSearch_cemr394{height:48px;}}
.MultiLookup_m84wgce{cursor:pointer;display:var(--m84wgce-0);position:absolute;top:50%;right:var(--m84wgce-1);width:33px;height:42px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);background-image:url("../../../public/icons/multi-lookup.svg");background-repeat:no-repeat;background-position:center;background-color:transparent;border:none;}@media (min-width:600px){.MultiLookup_m84wgce{height:48px;}}
.SearchHint_sy2musu{color:#3e5c6d;-webkit-text-decoration:var(--sy2musu-0);text-decoration:var(--sy2musu-0);font-size:16px;margin-top:3px;cursor:var(--sy2musu-1);margin-left:33px;}@media (min-width:600px){.SearchHint_sy2musu{margin-left:37px;}}.SearchHint_sy2musu:before{content:"i";display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:24px;height:24px;border-radius:50%;background-color:#50b5c3;color:#fff;line-height:20px;position:absolute;left:3px;top:22px;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}.SearchHint_sy2musu a{color:#50b5c3;-webkit-text-decoration:underline;text-decoration:underline;}
.SearchIconContainer_s96vdfy{display:none;width:44px;height:44px;background:none;border:none;position:absolute;left:2px;}@media (min-width:740px){.SearchIconContainer_s96vdfy{display:block;}}
.SearchIcon_sm1p3ny{display:inline-block;height:14px;width:14px;border:1px solid #868788;border-radius:50%;box-sizing:border-box;position:absolute;top:14px;left:8px;}.SearchIcon_sm1p3ny:after{content:"";display:block;width:100%;height:1px;background:#868788;position:absolute;top:100%;left:100%;-webkit-transform:rotate(45deg) translate(-25%,-25%);-ms-transform:rotate(45deg) translate(-25%,-25%);transform:rotate(45deg) translate(-25%,-25%);-webkit-transform-origin:center left;-ms-transform-origin:center left;transform-origin:center left;}@media (min-width:600px){.SearchIcon_sm1p3ny{border:3px solid #868788;height:18px;width:18px;left:14px;}.SearchIcon_sm1p3ny:after{height:3px;}}
.StyledContainer_s1tb6qvu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.SearchButton_sb1027f{display:block;font-weight:700;border-radius:0 3px 3px 0;padding:13px 27px;font-size:1.125rem;}.SearchButton_sb1027f > svg{margin:3px 0 -3px 0;}@media (min-width:740px){.SearchButton_sb1027f{padding:16px 22px;}}@media (min-width:1100px){.SearchButton_sb1027f{margin:0;}}
.SearchSubContent_syzjge4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;max-width:525px;padding-top:10px;position:relative;margin-bottom:-15px;}
